// tslint:disable:variable-name
import { SubCategory } from '@app/modules/category/models/category.model';
import { ConversionRates } from '@app/modules/company/models/company.modal';

export class Product {
  id?: number;
  attachment: Attachment[];
  name?: string;
  description?: string;
  category_id?: number;
  sub_category_id?: number;
  sub_category?: SubCategory;
  product_code?: string;
  hsn?: string;
  tax?: number;
  impa_code?: string;
  unit_id?: string;
  issa_code?: string;
  quantity?: number; // Cart use only
  current_port_id?: number; // Cart use only
  remark?: string; // Cart use only
  product_id?: number; // Cart use only
  ship_id?: number; // Cart use only
  category?: any; // Cart use only
  cart_id?: any; // Cart use only
  pack_type?: string;
  variant_product_id?: number;
  status?: number;
  images?: any[];
  unit?: any;
  is_requested?: boolean;
  variants?: any[];
  vendor?: any[];
  rfq_submitted?: boolean;
  price?: Price;
  country_id?: number;
  categories?: string[];
  conversion_rates?: ConversionRates;
  brand_name?: string;
  brand_id?: number;
  brand: Brand
}

export class Price {
  inr?: PriceDetail;
  usd?: PriceDetail;
  jpy?: PriceDetail;
  sgd?: PriceDetail;
  eur?: PriceDetail;
  aud?: PriceDetail;
  aed?: PriceDetail;
  sar?: PriceDetail;
  qar?: PriceDetail;
  deliverable_in?: string
}

interface Brand {
  name: string;
  id: number;
}

interface Attachment {
  title: string;
  url: string;
}
export class PriceDetail {
  price?: number;
  id?: number;
  margin?: number;
  tax?: number;
  remark?: string;
  expiry_date?: string;
}