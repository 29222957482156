import { SharedService } from './../../../../shared/service/shared.service';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { Product } from '../../model/product.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { UserRoles } from '@app/core/enums/user-roles';
import { CompanyService } from '@app/modules/company/services/company.service';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '@app/authentication/services/loading.service';
import { AlertService } from '@app/shared/service/alert.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  id: number;
  product: Product;
  imgForm: FormGroup;
  selectedId: any;
  modalRef: any;
  files: File[] = [];
  submitted: boolean;
  showLoader: boolean;
  role: number;
  userRoles = UserRoles;
  private unsubscribe$ = new Subject<any>();
  // @ViewChild('imageLink', {static: false}) imageLink: any;
  // descriptionText = [];
  loading = false;
  companyType: number;
  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private tostr: ToastrService,
    private sharedService: SharedService,
    private router: Router,
    private companyService: CompanyService,
    private loadingService: LoadingService,
    private alertService: AlertService
  ) {
    this.role = this.sharedService.getUserRole();
    this.companyType = this.sharedService.getCompany().type;
  }

  ngOnInit() {
    this.route.params.subscribe(value => {
      this.id = value.id;
    });
    this.imgForm = this.formBuilder.group({
      img_name: ['', [Validators.required]],
    });
    if (this.role === this.userRoles.ROLE_ADMIN || this.role === this.userRoles.ROLE_SUBADMIN || this.role === this.userRoles.ROLE_COMPANY_ADMIN && this.companyType === 2) {
      this.ProductData();
    } else {
      this.router.navigate(['/']);
    }
  }
  get form() {
    return this.imgForm.controls;
  }
  private ProductData() {
    this.loadingTrue();
    return this.productService.getByid(this.id).subscribe(value => {
      this.product = value;
      this.loadingFalse();
      // this.toSentenceCase(this.product.description);
    }, error => {
      this.loadingFalse();
      this.tostr.error(error.error.message);
      this.router.navigate(['product/']);
    });
  }

  openModal(template: TemplateRef<any>, id = null) {
    this.selectedId = id;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-md' })
    );
    this.alertService.backModalClose(this.modalRef);
  }

  selectedfileName(files: FileList) {
    if (this.product.images.length < 5 && this.product.images.length + files.length <= 5) {
      if (files.length > 0) {
        this.files = Array.from(files);
      }
    } else {
      this.files = [];
      this.form.img_name.setValue('');
      this.tostr.error("Only 5 product images can be uploaded!");
    }
  }

  addDocument() {
    this.submitted = true;
    if (!this.imgForm.valid) {
      return false;
    } else {
      this.showLoader = true;
      this.files.forEach((file, ind) => {
        let formData = this.sharedService.convertModelToFormData(this.imgForm.value);
        formData.append('image', file, file.name);
        formData.append('status', '1');
        formData.append('product_id', this.id.toString());
        formData.append('alt_tag', this.product.name);
        this.productService.uploadImg(formData).subscribe(data => {
          if (ind === this.files.length - 1) {
            this.ProductData();
            this.submitted = false;
            this.showLoader = false;
            formData = null;
            this.imgForm.reset();
            this.modalRef.hide();
            this.tostr.success('Image Successfully Update');
          }
        }, error => {
          this.submitted = false;
          this.showLoader = false;
          formData = null;
          this.tostr.error(error.error.message);
        });

      })

    }
  }

  // companyId(arg0: string, companyId: any) {
  //   throw new Error('Method not implemented.');
  // }
  // CompanyData() {
  //   throw new Error('Method not implemented.');
  // }

  deleteProduct() {
    this.loadingTrue();
    this.productService.deleteProduct(this.id).subscribe(data => {
      this.tostr.success(data.data.message);
      this.loadingFalse()
      this.goPreviousUrl();
      // this.router.navigate(['product/']);

    }, error => {
      this.loadingFalse()
      this.tostr.error(error.error.message);
    });
  }

  onImgError(event) {
    event.target.src = '/assets/images/placeholder_image.png';
  }

  goPreviousUrl() {
    if (this.product.is_requested) {
      this.router.navigate(['/product/request']);
      return;
    }
    this.companyService.getUrl.pipe(takeUntil(this.unsubscribe$))
      .subscribe(url => {
        if (url && url.page && url.filter) {
          return this.router.navigate(['/product'], { queryParams: { page: url.page, filter: url.filter } });
        } else {
          return this.router.navigate(['/product']);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
  // toSentenceCase(phrase) {
  //   let str = phrase.split(/[.]+/);
  //   console.log(str);
  //   //console.log(typeof str, typeof phrase);
  //   if(str && str.length>0) {
  //     str.forEach(item => {
  //       item
  //       .toLowerCase()
  //       .split(' ')
  //       .map(word => word.charAt(0).toLowerCase() + word.slice(1))
  //       .join(' ');

  //       if(item.charAt(0) !== ' ') {
  //         //console.log(item.charAt(0).toUpperCase() + item.slice(1));
  //         this.descriptionText.push(item.charAt(0).toUpperCase() + item.slice(1)+ '. ');
  //       } else {
  //         //console.log(item.charAt(1).toUpperCase() + item.slice(2));
  //         this.descriptionText.push(item.charAt(1).toUpperCase() + item.slice(2)+ '. ');
  //       }
  //     });
  //   }
  // console.log(item
  //   .toLowerCase()
  //   .split(' ')
  //   .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  //   .join(' '));
  // }

  loadingFalse() {
    this.loading = false;
    this.loadingService.setLoading(false);
  }

  loadingTrue() {
    this.loading = true;
    this.loadingService.setLoading(true);
  }
  openNewPage(str) {
    window.open(str, "_blank");
  }
}
