import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, Input, TemplateRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Product } from '@app/modules/product/model/product.model';
import { AlertService } from '@app/shared/service/alert.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CatalogueComponent } from '@app/modules/cetalogue/catalogue.component';
import { Router } from '@angular/router';
//import { AwsService } from '@app/shared/service/aws-bucket.service';

@Component({
  selector: 'app-product-popup',
  templateUrl: './product-popup.component.html',
  styleUrls: ['./product-popup.component.css']
})
export class ProductPopupComponent implements OnInit {
  attachmentModal;
  @Input() product: Product;
  viewTarget: boolean;
  modalRef: any;
  @ViewChild('productPopupModel', { static: false })
  productPopupModel: TemplateRef<any>;
  description: SafeHtml;
  zoomIn = true;
  @ViewChild('attachmentView', { static: false }) attachmentView;
  indicators: boolean = true;
  selectedIndex = 0;
  //controls: boolean = true;
  image = [];
  isReadMore: boolean = true;
  loading: boolean = true;
  imageUrl: string;
  bucket = 'shipskart-dev';
  key = 'assets/images/placeholder_image.png';
  constructor(private modalService: BsModalService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private router: Router,
    //private awsService: AwsService
  ) {
  }

  ngOnInit() {
  }

  onLoad() {
    this.loading = false;
    this.description = this.sanitizer.bypassSecurityTrustHtml(
      `<p><strong>Description: </strong>${this.product && this.product.description ? this.product.description : ''}</p>`
    );
  }

  async openModal(): Promise<void> {
    this.indicators = true;
    /* try {
      const arrayBuffer = await this.awsService.getObjectFromS3(this.bucket, this.product.images[0].img_name);
      const blob = new Blob([arrayBuffer]); // Adjust the MIME type based on your image type
      // Create a URL from the blob
      this.imageUrl = URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error fetching image from S3:', error);
    } */
    this.modalRef = this.modalService.show(
      this.productPopupModel,
      Object.assign(
        { backdrop: 'static', class: 'gray modal-md' }
      )
    );
    this.alertService.backModalClose(this.modalRef);
  }

  attachments() {
    if (this.product.attachment.length == 1) {
      this.router.navigate([]).then(result => {
        window.open(this.product.attachment[0].url)
      })
    } else {
      this.viewAttachmentModal();
    }
  }

  viewAttachmentModal() {
    this.attachmentModal = this.modalService.show(
      this.attachmentView,
      Object.assign(
        { backdrop: 'static', class: 'gray modal-md' }
      )
    );
    this.alertService.backModalClose(this.modalRef);
  }

  onImgError(event) {
    this.indicators = false;
    this.loading = true;
    event.target.src = '/assets/images/placeholder_image.png';
  }

  selectImage(index: number) {
    this.selectedIndex = index;
  }

  onPrevClick() {
    if (this.selectedIndex === 0) {
      this.image = [];
      this.product.images.forEach(img => {
        this.image.push(img);
        // variant.images.forEach(img => {
        //   this.image.push(img)
        // })
      })
      this.selectedIndex = this.image.length - 1;
    } else {
      this.selectedIndex--;
    }
  }

  onNextClick() {
    if (this.selectedIndex === this.image.length - 1) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex++;
    }
  }

  showText() {
    this.isReadMore = !this.isReadMore;
  }
}
