<!-- ID. {{companyId}} -->
<!-- <h3 class="breadcrum primary-font bmargin30"><a routerLink="/product"><span>Product</span></a><span><span
      class="brd-crm-arrow fa fa-angle-right"></span>Details</span>
</h3> -->
<!-- <app-loading class="loaderHide" [class.loaderShow]="loading"></app-loading> -->
<div class="top-index">
<a class="back-btn back inline-block bmargin20" *ngIf="product" href="javascript:;" (click)="goPreviousUrl()"><span class="fa fa-angle-left"></span>Back to list</a>
<div class="form-container bg-light-gray bmargin30" *ngIf="product">
    <button *ngIf="role==userRoles.ROLE_ADMIN" class="btn btn-danger pull-right" type="button" (click)="openModal(confirmaDelete)">Delete Product</button>
    <div class="single-box bmargin20 arrow-space">

        <h2 class="info-font bottom-line bmargin20">Product Information</h2>
        <div class="row">
            <div class="col-md-3 bmargin30">
                <div class="viewdetail-box">
                    <label class="bold-font-size-n">Name</label>
                    <span class="thin-font">{{product?.name}}</span>
                    <input type="hidden" class="editable" [readonly]="true" />
                    <!-- <input type="text"
            class="editable"
            [readonly]="true"
            [value]="order.order_number" /> -->
                </div>
            </div>
            <div class="col-md-3 bmargin30">
                <div class="viewdetail-box">
                    <label class="bold-font-size-n">Shipskart Code</label>
                    <span class="thin-font">{{product.product_code}}</span>
                </div>
            </div>
            <div class="col-md-3 bmargin30" *ngIf="product?.brand?.name">
                <div class="viewdetail-box">
                    <label class="bold-font-size-n">Brand Name</label>
                    <span class="thin-font">{{product?.brand?.name}}</span>
                </div>
            </div>
            <div class="col-md-3 bmargin30">
                <div class="viewdetail-box">
                    <label class="bold-font-size-n">IMPA Code</label>
                    <span class="thin-font">{{product.impa_code || 'N/A'}}</span>

                </div>
            </div>
            <!-- <div class="col-md-4 bmargin30">
        <div class="viewdetail-box">
          <label class="bold-font-size-n">Category</label>
          <span class="thin-font">{{product.category.name}}</span>
        </div>
      </div> -->
            <div class="col-md-3 bmargin30">
                <div class="viewdetail-box">
                    <label class="bold-font-size-n">Subcategory</label>
                    <span class="thin-font">{{product.sub_category?.name}}</span>
                </div>
            </div>
            <div class="col-md-3 bmargin30">
                <div class="viewdetail-box">
                    <label class="bold-font-size-n">ISSA code</label>
                    <span class="thin-font">{{product.issa_code || 'N/A'}}</span>
                </div>
            </div>
            <div class="col-md-3 bmargin30">
                <div class="viewdetail-box">
                    <label class="bold-font-size-n">HSN Code</label>
                    <span class="thin-font">{{product.hsn || 'N/A'}}</span>
                </div>
            </div>
            <div class="col-md-3 bmargin30">
                <div class="viewdetail-box">
                    <label class="bold-font-size-n">Unit</label>
                    <span class="thin-font" *ngIf="product.unit">{{product.pack_type}} {{product.unit.name}}</span>
                </div>
            </div>
            <div class="col-md-3 bmargin30" *ngIf="product.tax">
                <div class="viewdetail-box">
                    <label class="bold-font-size-n">Tax</label>
                    <span class="thin-font">{{product.tax+'%'}}</span>
                </div>
            </div>
            <div class="col-md-3 bmargin30">
                <div class="viewdetail-box">
                    <label class="bold-font-size-n">Status</label>
                    <span class="thin-font"
                        [innerHtml]="product.status | status"></span>
                </div>
            </div>
            <div class="col-md-12">
                <div class="viewdetail-box">
                    <label class="bold-font-size-n">Description</label>
                    <span class="thin-font" [innerHtml]="product.description"></span>
                </div>
            </div>

        </div>
    </div>

    <div class="single-box arrow-space">
        <h2 class="info-font bottom-line bmargin20 tmargin50">Product Images <button class="btn btn-primary btn-height" type="button" (click)="openModal(docPopup)">Add Image</button></h2>
        <div class="row">
            <div class="col-md-2 col-sm-3 bmargin30" *ngFor="let prod of product.images ">
                <!-- <a class="btncancel">&#10006;</a> -->
                <img *ngIf="product.images.length != 0 &&  product.images[0].path" class="product-img prod-img" [src]="prod.icon_path" (error)="onImgError($event)"/>
            </div>
            <div class="col-md-2 bmargin30" *ngIf="product.images.length == 0 || product.images && product.images[0].path == null">
                <img class="product-img" src= "/assets/images/placeholder_image.png" />
            </div>
        </div>

        <div class="row" *ngIf="product?.is_requested && role==userRoles.ROLE_ADMIN">
            <div class="col-md-4 bmargin30">
                <a class="btn btn-primary column-item" routerLink="/product/edit/{{product.id}}">Add to Catalogue</a>
            </div>
        </div>
    </div>
    <div class="single-box arrow-space" *ngIf="product && product.attachment.length>0">
        <h2 class="info-font bottom-line bmargin20 tmargin50">Product Attachment(s) </h2>
        <div class="row">
            <ol *ngFor="let prod of product.attachment ">
                <!-- <a class="btncancel">&#10006;</a> -->
                <li><a href="javascript:void(0)" target="_blank" (click)="openNewPage(prod.url)">{{prod.title}}</a></li>
            </ol>
        </div>
    </div>
</div>
</div>
<ng-template #docPopup>
    <div class="modal-body cancelmodal-spc">
        <h4 class="sure-font">Add Image</h4>
        <div class="tmargin20">
            <form [formGroup]="imgForm" (ngSubmit)="addDocument()">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-group" [ngClass]="{error : submitted && form.img_name.errors}">
                            <label for="">Image<span class="mendatory">*</span></label>
                            <span>Max file size : 5 MB</span>
                            <input type="file" formControlName="img_name" class="form-control" (change)="selectedfileName($event.target.files)" multiple>
                            <p class="file-upload-info">Max file size : 5 MB and Maximum 5 Images can be upload.</p>
                            <!-- <div class="d-flex">
                                <div class="img-responsive" *ngFor="let img of uploadImg">
                                    <img [src] ="img" alt= "please upload image" width="100" height="100">
                                </div>
                            </div> -->
                            <div class="errorbox" *ngIf="submitted && form.img_name.errors">
                                <span>Please upload Image</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 tmargin10 text-right">
                        <button class="btn btn-outline-primary" (click)="modalRef.hide();submitted = false;form.img_name.setValue('');" type="button">
              Cancel
            </button>
                        <button class="btn btn-primary" type="submit" [disabled]="showLoader">
              Add Image
              <ng-container *ngIf="showLoader">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Loading...</span>
            </ng-container>
            </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #confirmaDelete>
    <div class="modal-body cancelmodal-spc text-center">
        <h4 class="sure-font">Are you sure you want to delete the product from the catalogue?</h4>
        <div class="text-center tmargin20">
            <button class="btn btn-outline-primary" (click)="modalRef.hide()" type="button">
        Cancel
      </button>
            <button class="btn btn-danger text-white" (click)="deleteProduct();modalRef.hide()">Delete Product</button>
        </div>
    </div>
</ng-template>
